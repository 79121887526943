<template>
  <v-card :color="ant" height="120">
    <v-card-text>
      <v-row class="ml-0">
        <v-badge
          bordered
          bottom
          :color=" detail.is_online ? 'success' : 'pink accent-4'"
          dot
          offset-x="10"
          offset-y="20"
          class="mt-2"
        >
          <v-avatar size="80" color="grey">
            <img
							:src="avatarURl"
							alt="John"
							v-if="avatarURl"
							/>
							<span v-else class='text-h3'>  {{ detail.nickname ? trimFirtTwo(detail.nickname) :  detail.id  }}</span>
          </v-avatar>
        </v-badge>

        <div class="ml-5">
          <div class="text-subtitle-1 font-weight-bold  mt-2">
            {{ fullName }}
            <v-badge
              inline
              icon="mdi-close-box-outline"
              color="red"
              left
              class="mt-5 ml-2"
              tile
              v-if="$route.name === 'blocked'"
            >
              <span class="ml-2">Blocked Writer</span>
            </v-badge>
          </div>
          <div class="text-subtitle-2 mt-1">
            {{ detail.short_about }}
          </div>
          <v-row class="mt-2 ml-0 text-subtitle-2 ">
            <span>
              {{ detail.total_orders }} <span class="grey--text"> ORDERS</span> 100%
              <span class="grey--text">SUCCESS</span> {{ detail.rating }}</span
            >
            <v-rating
              :value="parseInt(detail.rating)"
              half-increments
              background-color="orange lighten-3"
              color="orange"
              class="mt-n2"
              readonly
              size="15"
            ></v-rating>
            ({{ detail.total_reviews }}
            <span class="grey--text ml-1"> REVIEWS </span>)
          </v-row>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import colorMxn from "@/mixins/colorMxn";
import generalMxn from '@/mixins/general_mixin';

export default {
  name: "writerComponent",
  props: ["detail"],
  mixins: [colorMxn, generalMxn],
  data() {
    return {
      rating: 4.8,
    };
  },
  computed: {
     avatarURl() {
      // const url = this.detail.avatar ? `${process.env.VUE_APP_BASE_URL}/avatar/download/${this.detail.avatar}` : null;
      return this.detail.avatar;
    },
    fullName() {
      const res = this.detail.user.first_name && this.detail.user.last_name ? this.detail.user.first_name +  this.detail.user.last_name : this.detail.user.username;
      return res;
    }
  },
};
</script>

<style scope></style>
